<template>
    <AppLayout>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h2 class="text-h3 font-weight-light">Start your free trial</h2>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-5">
            <v-col style="text-align: center">
                <p class="text-body-1 font-weight-light">This will be quick. Credit card is <strong>NOT</strong> required.</p>
            </v-col>
        </v-row>
        <!-- <QuickStartSteps :highlight="highlight" :current="step"/> -->

        <!-- webauthz prompt form -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="isReady && !error">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="4" class="px-5 pb-5">
                    <v-card-text class="text-h6 text-center">
                        <span>Grant access to application</span>
                    </v-card-text>
                    <v-form v-model="promptForm" ref="promptFormRef" @submit="validatePromptForm" onSubmit="return false;" @keyup.enter.native="validatePromptForm">
                        <v-row justify="center" class="px-5 py-5">
                            <v-col>
                                <p class="font-weight-light text-center">
                                    <span class="font-weight-bold">{{ clientName }}</span>
                                    (<span class="font-weight-bold"><a :href="clientURI" target="_blank">{{ clientDomain }}</a></span>)
                                </p>
                                <p class="font-weight-light text-center">
                                    <span class="text-body-2">{{ clientVersion }}</span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="px-5 pb-5" v-if="isEnterpriseRealm">
                            <p class="font-weight-light text-center">The application is requesting permission to manage an authentication realm
                                <span class="font-weight-bold">{{ targetRealm.label }}</span>
                                (<span class="font-weight-bold">{{ targetRealm.domain }}</span>)
                                in the enterprise account
                                <span class="font-weight-bold">{{ targetAccount.name }}</span>.
                            </p>
                            <!-- <p class="font-weight-light">
                                Enterprise account:
                                <span class="font-weight-bold">{{ targetAccount.name }}</span>
                            </p>
                            <p class="font-weight-light">
                                Realm:
                                <span class="font-weight-bold">{{ targetRealm.label }}</span>
                                (<span class="font-weight-bold">{{ targetRealm.domain }}</span>)
                            </p> -->
                        </v-row>

                        <!-- <v-row justify="center" class="px-5 py-5" v-if="targetAccountId">
                            <v-list class="ma-0 pa-0">
                                <v-list-item class="lighten-5" style="padding-left: 8px;">
                                    <v-list-item-avatar tile style="margin-right: 8px;">
                                        <Avatar :attr="targetAccount" :size="32"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ targetAccount.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ targetAccount.type }}</v-list-item-subtitle>
                                        <v-list-item-content v-if="targetRealmId">
                                            {{ targetRealm.label }} ({{ targetRealm.domain }})
                                        </v-list-item-content>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-row> -->

                        <v-row justify="center" class="px-5" v-if="isGranted">
                            <p class="font-weight-bold">You granted this access.</p>
                            <!-- TODO: show information or link on how to revoke the access -->
                        </v-row>
                        <v-row justify="center" class="px-5" v-if="isDenied">
                            <v-col class="pa-0">
                                <p class="font-weight-bold">You denied this access.</p>
                                <p class="font-weight-light">To grant access, return to the application and start a new request.</p>
                            </v-col>
                        </v-row>

                        <!-- <v-row justify="center" class="px-5 py-5" v-if="isPrompt">
                            <v-alert dense type="warning" border="left">
                                <template v-slot:prepend>
                                    <font-awesome-icon icon="exclamation-triangle" class="white--text"/>
                                </template>
                                <span class="ml-2">Check the application name and domain -- are they correct?</span>
                            </v-alert>
                        </v-row> -->

                        <v-row justify="center" v-if="isPrompt">
                        <v-card-actions>
                            <v-btn elevation="4" @click="onGrantAccess()" :disabled="!isReady" class="blue white--text">
                                <span>Continue</span>
                            </v-btn>
                            <v-btn @click="onDenyAccess()" :disabled="!isReady" text class="grey--text">
                                <span>Cancel</span>
                            </v-btn>
                        </v-card-actions>
                        </v-row>
                        <v-row justify="center" v-show="isActionPending">
                            <p class="text-h5 font-weight-light">Saving...</p>
                        </v-row>

                        <v-row justify="center" v-if="redirect">
                            <p class="text-body-1 font-weight-light"><a :href="redirect">Continue to application</a></p>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>

        <!-- error message -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="error">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <p class="red--text text-center">We cannot process this request.</p>
                <p class="text-center"><a href="/contact/?topic=support">Contact support</a></p>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import loginshield from '@/client';
import AppLayout from '@/components/AppLayout.vue';
// import Avatar from '@/components/Avatar.vue';

export default {
    components: {
        AppLayout,
        // Avatar,
    },
    data() {
        return {
            interactionId: null,
            promptForm: false, // true when all field inputs are ready to submit
            isEnterpriseRealm: false, // true when application is requesting access to an enterprise realm
            clientName: null,
            clientVersion: null,
            clientURI: null,
            clientDomain: null,
            targetAccountId: null,
            targetAccount: null, // object with account info
            targetRealmId: null,
            targetRealm: null, // object with realm info
            isReady: false, // true when prompt information is loaded from server
            isActionPending: false, // true while sending grant to server
            isPrompt: false, // true when prompting the user to decide
            isGranted: false, // true when loading a prompt that was already completed with action: grant
            isDenied: false, // true when loading a prompt that was already completed with action: deny
            redirect: null, // string (url) when a redirect back to the application is available
            error: false,
        };
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            accountMap: (state) => state.accountMap,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isGrantFormComplete() {
            return true;
        },
    },
    methods: {
        async init() {
            console.log('webauthz/prompt.vue: init, interactionId: %s', this.interactionId);

            if (this.interactionId) {
                const { type, state } = await this.$store.dispatch('loadInteraction', this.interactionId);
                if (type === 'webauthz') {
                    console.log(`webauthz/prompt.vue: webauthz interaction: ${JSON.stringify(state)}`);
                    await this.prepareWebauthzPrompt(state);
                } else {
                    console.log('webauthz/prompt.vue: invalid interaction');
                    this.error = true;
                }
            } else {
                console.log('webauthz/prompt.vue: interaction id required');
                this.error = true;
            }
        },
        async prepareWebauthzPrompt({ /* clientId, clientState, realm, */ scope, status, clientName, clientVersion, clientURI, accountId, realmId } = {}) {
            console.log(`prepareWebauthzPrompt: scope ${scope} status ${status} clientName ${clientName} clientVersion ${clientVersion} clientURI ${clientURI} accountId ${accountId} realmId ${realmId}`);
            this.clientName = clientName || 'Unknown application';
            this.clientVersion = clientVersion;
            this.clientURI = clientURI;
            this.targetAccountId = accountId;
            this.targetRealmId = realmId;
            if (clientURI) {
                try {
                    const url = new URL(clientURI);
                    this.clientDomain = url.host;
                } catch (err) {
                    console.error('prepareWebauthzPrompt failed to parse client uri', err);
                    this.clientDomain = this.clientURI;
                }
            }
            switch (scope) {
            case 'enterprise-realm':
                this.isEnterpriseRealm = true;
                // load the account and realm info
                if (!accountId || !realmId) {
                    console.error('prepareWebauthzPrompt: accountId and realmId required for scope enterprise-realm');
                    this.error = true;
                    break;
                }

                /*
                try {
                    this.$store.commit('loading', { loadAccountById: true });
                    this.targetAccount = await loginshield.account.get(accountId);
                } catch (err) {
                    console.log('init: cannot load message', err);
                } finally {
                    this.$store.commit('loading', { loadAccountById: false });
                }
                */
                this.targetAccount = this.accountMap[accountId];

                try {
                    this.$store.commit('loading', { loadRealmById: true });
                    this.targetRealm = await loginshield.realm.getById(realmId);
                } catch (err) {
                    console.log('init: cannot load realm info', err);
                } finally {
                    this.$store.commit('loading', { loadRealmById: false });
                }
                break;
            default:
                console.error(`webauthz/prompt.vue: unknown scope ${scope}`);
                this.error = true;
                break;
            }
            switch (status) {
            case 'deny':
                console.error('webauthz/prompt.vue: webauthz request already completed');
                this.isDenied = true;
                break;
            case 'grant':
                console.error('webauthz/prompt.vue: webauthz request already completed');
                this.isGranted = true;
                break;
            case 'start':
                this.isPrompt = true;
                break;
            case 'prompt':
                this.isPrompt = true;
                break;
            default:
                console.error(`webauthz/prompt.vue: invalid interaction status ${status}`);
                this.error = true;
                break;
            }
            this.isReady = true;
        },
        validatePromptForm() {
            if (this.$refs.promptFormRef.validate()) {
                this.onGrantAccess();
            }
        },
        async onGrantAccess() {
            console.log('onGrantAccess');
            if (this.isGrantFormComplete) {
                console.log('onGrantAccess: form complete');

                const grantRequest = {
                    action: 'grant',
                };

                // send to server
                try {
                    this.isActionPending = true;
                    this.$store.commit('loading', { onSubmitOptin: true });
                    const grantResponse = await loginshield.interaction.edit(this.interactionId, grantRequest);
                    this.$store.commit('loading', { onSubmitOptin: false });
                    if (typeof grantResponse.redirect === 'string') {
                        this.redirect = grantResponse.redirect; // make a link available in case window.location change is prevented
                        window.location = grantResponse.redirect;
                    } else {
                        console.log('grant request failed with response: %o', grantResponse);
                    }
                } catch (err) {
                    console.log('grant request failed: %o', err);
                } finally {
                    this.isActionPending = false;
                }
            }
        },
        async onDenyAccess() {
            console.log('onDenyAccess');
            if (this.isGrantFormComplete) {
                console.log('onDenyAccess: form complete');

                const denyRequest = {
                    action: 'deny',
                };

                // send to server
                try {
                    this.isActionPending = true;
                    this.$store.commit('loading', { onSubmitOptin: true });
                    const denyResponse = await loginshield.interaction.edit(this.interactionId, denyRequest);
                    this.$store.commit('loading', { onSubmitOptin: false });
                    if (typeof denyResponse.redirect === 'string') {
                        this.redirect = denyResponse.redirect; // make a link available in case window.location change is prevented
                        window.location = denyResponse.redirect;
                    } else {
                        console.log('deny request failed with response: %o', denyResponse);
                    }
                } catch (err) {
                    console.log('deny request failed: %o', err);
                } finally {
                    this.isActionPending = false;
                }
            }
        },
    },

    watch: {
        isAuthenticatedReady(value, oldValue) {
            console.log('quickstart.vue: isAuthenticatedReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    mounted() {
        this.interactionId = this.$route.query.i;

        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
